import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBCol, MDBRow } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import FadeInWhenVisible from '../components/fadeInWhenVisible'
import Card from '../components/cardResources'
import Cta from '../components/cta'

const Resources = ({ data }) => {
  const post = data.markdownRemark
  
  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white">
        <FadeInWhenVisible>
          <MDBContainer>
            <MDBRow>
              <MDBCol>
              <div className="font-w-400 text-xs-extra-large divlink text-opening-para" dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara, }} />
              <div className="text-medium divlink mt-3" dangerouslySetInnerHTML={{ __html: post.html }} />
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          </FadeInWhenVisible>
        </section>

        <section className="bg-white pt-0">
          <FadeInWhenVisible>
            <MDBContainer>
              {post.frontmatter.section && (
                <MDBRow>
                  {post.frontmatter.section.map((sections, index) => (
                    <Card
                      key={index}
                      collg="4"
                      colmd="6"
                      height="3.5rem"
                      title={sections.title}
                      subtitle=""
                      description={sections.subtitle}
                      colour={sections.colour}
                      image={sections.image.childImageSharp.gatsbyImageData}
                      alt={sections.alttext}
                      link={sections.link}
                    />
                  ))}
                </MDBRow>
              )}
            </MDBContainer>
          </FadeInWhenVisible>
        </section>


        <Cta
          ctatitle={post.frontmatter.cta.title}
          ctadescription={post.frontmatter.cta.description}
          ctalink={post.frontmatter.cta.link}
          ctalinktext={post.frontmatter.cta.linktext}
        />
      </main>
    </Layout>
  )
}
export default Resources

export function Head({data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query {      
    markdownRemark(
      fields: {}
      frontmatter: { template: { eq: "other" }, name: { eq: "resources" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        openingpara
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        alttext
        section {
          title
          subtitle
          alttext
          description
          colour 
          image {
            childImageSharp {
              gatsbyImageData(width: 110, quality: 90) 
            }
          }
          link
        }
        cta {
          title
          description
          linktext
          link
        }
      }
    }
  }
`