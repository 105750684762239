import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"
import { MDBCol, MDBCard, MDBCardUp, MDBAvatar, MDBCardBody, MDBCardText } from 'mdbreact'
import CustomLink from './CustomLink'

class CardResources extends React.Component {
  render() {
    const colHeight = this.props.height != null ? this.props.height : 'auto'
    return (
      <MDBCol lg={this.props.collg} md={this.props.colmd} className="pb-card d-flex" >
        <MDBCard className="flex-fill" testimonial>
          <MDBCardUp className={this.props.colour ? this.props.colour : "blue-gradient"} />
          <MDBAvatar className="mx-auto white">
            <GatsbyImage
              top
              image={this.props.image}
              overlay="white-slight"
              hover
              waves
              alt={this.props.alt}
              className="rounded-top drop-shadow"
            />
          </MDBAvatar>
          <MDBCardBody>
            <div style={{ maxHeight: colHeight, minHeight: colHeight }}>
              <h3 className="font-alt font-w-700 letter-spacing-1 title-small title-xs-medium mt-2">
                {this.props.title}
              </h3>
            </div>
            <hr />

            {this.props.link ? (
              <MDBCardText className="font-w-400 text-medium mt-2 mb-2">
                <CustomLink link={this.props.link} className="effect" >
                  {this.props.description}
                </CustomLink>
              </MDBCardText>
            ) : (
              <MDBCardText className="font-w-400 text-medium mt-2 mb-2"></MDBCardText>
            )}
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    )
  }
}
export default CardResources